import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button as ButtonMui } from "@material-ui/core";
import { Icon } from "@iconify/react";
import clsx from "clsx";

const useStyles = makeStyles({
	root: {
		borderRadius: 0,
		boxShadow: "none",
		textTransform: "none",
		fontSize: 16,
		padding: "12px 20px",
		border: "1px solid",
		lineHeight: 1.5,
		textWrap: "nowrap",
        "&:focus": {
            boxShadow: "none",
        },
	},
	default: {
		backgroundColor: "#007AFF",
		color: "#FFFFFF",
		borderColor: "#085CC5",
		"&:hover, &:active": {
			backgroundColor: "#085CC5",
			borderColor: "#0D509B",
		},
	},
    outlined: {
		backgroundColor: "#FFFFFF",
		color: "#007AFF",
		borderColor: "#007AFF",
		"&:hover, &:active": {
            backgroundColor: "#FFFFFF",
			color: "#085CC5",
            borderColor: "#0D509B",
		},
	},
});

export const Button = ({ children, color, icon, onClick }) => {
	const classes = useStyles();

	return (
		<ButtonMui
			variant="contained"
			className={clsx(classes.root, {
				[classes.outlined]: color === "outlined",
				[classes.default]: !color || color === "default",
			})}
			startIcon={icon && (<Icon icon={icon} />)}
			onClick={onClick}
		>
			{children}
		</ButtonMui>
	);
};
